angular.module("app")
    .controller("asideSprayController", function($timeout, $scope, $rootScope, $uibModal, _mapFieldService, _view, _v, _apiService,
                                                 _flashService, Notification, _i18n, _mapAddTaskService, $q, gettextCatalog, _chartService, _logicService, _formatService,
                                                 _taskService) {

        const GRAPH_X1 = 1,
              GRAPH_X2 = 2,
              GRAPH_HALF = 0.5;

        $scope.dateFormat = _formatService.getDatePickerFormatForDate();

        $scope.$on('_view:urlReady', function() {
            if (_view.is.weather() && _view.is.viewSpray()) {
                initViewSpray();
            }
        });

        $scope.$on('_view:viewSprayChanged', function(event, args) {
            clearViewSprayWhenTasksWereSwitched(args.tasks);
        });

        $scope.$on('_view:editSprayChanged', function(event, args) {
            clearEditSprayWhenTasksWereSwitched(args.tasks);
        });

        $scope.$on('_view:closeAllScreens', function () {
            if (_view.is.viewSpray()) {
                clearViewSpray();
            }

            if (_view.is.editSpray()) {
                clearEditSpray();
            }
        });

        $scope.$on('_map:mapReady', function() {
            if (_view.is.viewSpray()) {
                initViewSpray();
            } else if (_view.is.editSpray()) {
                initEditSpray();
            }
        });

        var colors = ["#64AFC7", "#84C486", "#9DA0C7", "#b47d90", "#ECBBA9", "#EAE5A2",
            "#A2EADA", "#C5A2EA", "#EAC8A2", "#B4EAA2", "#EAA2DD", "#EAD6A2", "#A2C9EA", "#EAA2AB", "#E0EAA2", "#A8ABEB"];

        function clearEditSprayWhenTasksWereSwitched(tasks) {
            if (tasks.prev !== tasks.current) {
                clearEditSpray();
            }

            initEditSpray();
        }

        function initEditSpray() {
            if (_view.is.editSpray()) {
                $scope.isLoading = true;

                document.querySelector('body').onkeydown = function (e) {
                    if (e.keyCode == 27) {
                        document.querySelector('body').onkeydown = null;
                        $rootScope.$safeApply(function() {
                            $scope.closeEditSpray();
                        });
                    }
                };

                var fieldId = (_view.isFieldSelected() || _view.isFarmAndFieldSelected()) ? _view.getCurrentField().id : _view.getPanelField().id;

                _mapFieldService.panToFields([{id: fieldId}]);
                _mapFieldService.hideAllFieldsExceptOne(fieldId);

                $scope.fieldIsSimple = _mapFieldService.isSimple(fieldId);

                $scope.step = 1;

                $scope.currentMix = null;
                $scope.filterMix = '';
                $scope.products = [];
                $scope.filterProduct = '';
                $scope.resetCustomMix();

                if (_view.getEditSpray() != -1) {
                    _apiService.getSpray(_view.getCurrentAccount(), _view.getEditSpray()).then(
                        sprayResponse => {
                            $scope.editedSpray = sprayResponse.data;
                            createSpray($scope.editedSpray);
                            sprayDateModel();

                            if ($scope.spray.method.current.id == 1) {
                                $scope.gridTypeSelect('new', true);
                            }
                        }, function () {
                            $scope.isLoading = false;
                            Notification.error(_i18n.getString("common.notifications.apiError"));
                        });
                } else {
                    createSpray(null);
                    sprayDateModel();
                }
            } else {
                clearEditSpray();
            }
        }

        function createSpray(editedSpray) {
            $scope.spray = null;
            var firstDayOfWeek = _view.getCurrentAccountInfo().settings.firstDayOfWeek || 1;
            var taskDate = _flashService.get('taskDate');
            var dateStart = taskDate ? taskDate.startOf('day') : moment.utc().startOf('day');
            var dateEnd = dateStart.clone();

            if (editedSpray) {
                dateStart = moment.utc(editedSpray.datePublished);
                dateEnd = moment.utc(editedSpray.dateDue).startOf('day');
            }


            $scope.spray = {
                dateStart: {
                    date: _logicService.momentUtcToDatepickerDate(dateStart),
                    inlineOptions: {
                        startingDay: firstDayOfWeek // 0-6 (Sunday to Saturday)
                    }
                },
                dateEnd: {
                    date: _logicService.momentUtcToDatepickerDate(dateEnd),
                    inlineOptions: {
                        startingDay: firstDayOfWeek // 0-6 (Sunday to Saturday)
                    }
                },
                method: {
                    options: [
                        {id: 0, name: gettextCatalog.getString('Partial')},
                        {id: 1, name: gettextCatalog.getString('Full')}
                    ],
                    current: 0
                },
                tankSize: editedSpray ? editedSpray.loadsConfig.tankSize : 4000,
                rate: editedSpray ? editedSpray.loadsConfig.rate : 80,
                bandwidth: editedSpray ? editedSpray.loadsConfig.bandwidth : 30
            };

            $scope.spray.method.current = editedSpray ? (editedSpray.type == 'PARTIAL' ? $scope.spray.method.options[0] : $scope.spray.method.options[1]) : $scope.spray.method.options[0];
            $scope.spray.freeSpray = editedSpray ? (editedSpray.loadsType == 'FREE') : false;
        }

        function sprayDateModel() {
            if (_.isNull($scope.spray.dateStart.date) || _.isUndefined($scope.spray.dateStart.date) || _.isNull($scope.spray.dateEnd.date) || _.isUndefined($scope.spray.dateEnd.date)) {
                $scope.isLoading = false;
                return;
            }

            var currentFieldId = (_view.isFieldSelected() || _view.isFarmAndFieldSelected()) ? _view.getCurrentField().id : _view.getPanelField().id;

            $scope.fieldSize = _mapFieldService.fieldArea(currentFieldId);
            $scope.insects = [];
            $scope.cropMonitor = null;

            _apiService.fieldCrops(_view.getCurrentAccount(), currentFieldId, true).then(function(d) {
                if (_.size(d.data) > 0) {
                    $scope.crops = d.data;
                    $scope.crop = _logicService.findCropNowAndFuture(_logicService.javascriptDateToMoment($scope.spray.dateStart.date), _logicService.javascriptDateToMoment($scope.spray.dateEnd.date), $scope.crops);
                    $scope.futureCrop = $scope.crop && _logicService.javascriptDateToMoment($scope.spray.dateStart.date).isBefore(moment.utc($scope.crop.seedingDate));

                    var cropMonitor = findCropMonitor(_logicService.javascriptDateToMoment($scope.spray.dateStart.date), $scope.crop);

                    _mapFieldService.hideAllFieldsExceptOne(currentFieldId);

                    if (cropMonitor) {
                        $q.all({
                            taskResponse: _apiService.getTaskDetails(_view.getCurrentAccount(), cropMonitor.id),
                            insectsResponse: _apiService.getInsects(_view.getCurrentAccount()),
                            taskInsectsResponse: _apiService.getTaskInsects(_view.getCurrentAccount(), cropMonitor.id)
                        }).then(function (values) {
                            $scope.cropMonitor = values.taskResponse.data;

                            if ($scope.spray.method.current.id == 0) {

                                _mapFieldService.drawTaskGrid($scope.cropMonitor.grid, $scope.cropMonitor);
                                _mapFieldService.drawLoadStart($scope.cropMonitor.grid, $scope.editedSpray);
                                _mapFieldService.drawTaskResults($scope.cropMonitor.points);

                                $scope.insects = _taskService.buildTaskInsects(values.insectsResponse.data, values.taskInsectsResponse.data);

                                if ($scope.editedSpray) {
                                    $scope.insects.forEach(insect => {
                                        if ($scope.editedSpray.loadsConfig.insects.includes(insect.id)) {
                                            insect.selected = true;
                                        }
                                    });
                                }
                                $scope.drawDangerMap();
                            }
                        }, function () {
                            $scope.isLoading = false;
                            Notification.error(_i18n.getString("common.notifications.apiError"));
                        });
                    } else {
                        $scope.isLoading = false;
                    }

                    $scope.sprayTracks = [{id: -1, name: 'Select track'}];

                    _apiService.cropTracks(_view.getCurrentAccount(), $scope.crop.id).then(function(response) {
                        var tracks = _.map(response.data, function(obj) {
                            return {id: obj.id, name: 'track_' + obj.id + " " + _formatService.formatDateAndTime(obj.dateEnd)};
                        });
                        tracks = _.sortBy(tracks, 'id').reverse();
                        $scope.sprayTracks = $scope.sprayTracks.concat(tracks);
                        $scope.input = {sprayTrack : $scope.sprayTracks[0], dividers: 1, selectedTrackOnly: false};
                    }, () => {
                        Notification.error(_i18n.getString("common.notifications.apiError"));
                    });
                }
            }, function(){
                $scope.isLoading = false;
                Notification.error(_i18n.getString("common.notifications.apiError"));
            });
        }

        $scope.customMixController = {
            remove: function(product) {
                var index = $scope.customMix.products.indexOf(product);
                $scope.customMix.products.splice(index, 1);
            },
            save: function() {
                var saveBean = {name: $scope.customMix.name};
                if ($scope.customMix.id) {
                    saveBean.id = $scope.customMix.id;
                }
                saveBean.entries = _.map($scope.customMix.products, function(p) {return {consumption: p.consumption, chemical: p.id}});

                if (saveBean.id) { // existing mix
                    _apiService.updateMix(_view.getCurrentAccount(), saveBean).then(
                        response => {
                            Notification.success(gettextCatalog.getString('Mix updated'));

                            $scope.mixes.forEach(mix => {
                                if (mix.id == $scope.customMix.id) {
                                    mix = angular.copy($scope.customMix);
                                }
                            });

                            // reset custom mix
                            $scope.resetCustomMix();
                        },
                        () => {
                            Notification.error(_i18n.getString("task.notifications.saveAssignment.error"));
                        }
                    );
                } else { // new mix
                    delete saveBean.id;
                    _apiService.saveMix(_view.getCurrentAccount(), saveBean).then(
                        response => {
                            Notification.success(gettextCatalog.getString('Mix saved'));

                            $scope.customMix.id = response.data.id;
                            $scope.mixes.push(_.clone($scope.customMix));
                            $scope.currentMix = $scope.mixes[$scope.mixes.length - 1];

                            $scope.resetCustomMix();
                        },
                        () => {
                            Notification.error(_i18n.getString("task.notifications.saveAssignment.error"));
                        }
                    );
                }
            }
        };

        $scope.save = () => {
            $scope.isLoading = true;
            var currentFieldId = (_view.isFieldSelected() || _view.isFarmAndFieldSelected()) ? _view.getCurrentField().id : _view.getPanelField().id;

            var sumConsumption = _.reduce($scope.currentMix.products, function(memo, product){ return memo + product.consumption; }, 0);
            if (sumConsumption > $scope.spray.rate) {
                $scope.isLoading = false;
                Notification.error(gettextCatalog.getString('Product sum rate is greater than mix rate'));
                return;
            }

            var from = _logicService.javascriptDateToMoment($scope.spray.dateStart.date).startOf("day");
            var to = _logicService.javascriptDateToMoment($scope.spray.dateEnd.date).endOf("day");

            if (to.isBefore(from)) {
                to = from.clone().endOf("day");
            }

            if (!_logicService.checkFreeDate($scope.crop, from, to, $scope.editedSpray ? $scope.editedSpray.id : null)) {
                $scope.isLoading = false;
                Notification.error(gettextCatalog.getString("Task dates are occupied by other task."));
                return;
            }

            if ($scope.spray.method.current.id == 0 && $scope.futureCrop) { //partial spray before sowing
                $scope.isLoading = false;
                Notification.error(gettextCatalog.getString("Partial spray can't be before sowing."));
                return;
            }

            if ($scope.spray.method.current.id == 1 && !$scope.cropMonitor) { //full spray with new grid
                if (!_logicService.checkFullSprayCycle($scope.crop, from, to)) {
                    $scope.isLoading = false;
                    Notification.error(gettextCatalog.getString("Full spray with new grid can not be after crop monitor or partial spray."));
                    return;
                }
            }

            if (_.size($scope.loads) == 0) {
                $scope.isLoading = false;
                Notification.error(gettextCatalog.getString("Spray must have at least one load"));
                return;
            }

            var allCellsOnField = true;
            _.each($scope.loads, function(load) {
                _.each(load.cells, function(c) {
                    if (!_mapFieldService.testCellOnField(c)) {
                        allCellsOnField = false;
                    }
                })
            });
            if (!allCellsOnField) {
                $scope.isLoading = false;
                Notification.error(gettextCatalog.getString("All cells in loads must contain field"));
                return;
            }

            var grid = null;
            if ($scope.spray.method.current.id == 0) {
                grid = $scope.cropMonitor.grid;
            } else {
                grid = $scope.fullSprayGrid;
            }

            var saveBean = {
                datePublished: from.format(_logicService.DATETIME_FORMAT),
                dateDue: to.format(_logicService.DATETIME_FORMAT),
                title: ($scope.spray.method.current.id == 0 ? "Spray" : "Full Spray"),
                loads: $scope.loads,
                type: ($scope.spray.method.current.id == 0 ? "PARTIAL" : "FULL"),
                grid: _.clone(grid),
                loadsType: ($scope.spray.freeSpray ? "FREE" : "STRICT")
            };
            delete saveBean.grid.id;

            saveBean.loads.forEach(load => {
                var loadArea = _mapFieldService.loadArea(load, currentFieldId);
                load.composition = [];
                loadArea = loadArea * 1.05 / 10000; //1.05 is little saving
                var water = _logicService.round(loadArea * $scope.spray.rate, 2);
                $scope.currentMix.products.forEach(product => {
                    var l = _logicService.round(product.consumption * loadArea, 2);
                    water = water - l;
                    load.composition.push({id: product.id, volume: l, name: product.name, consumption: product.consumption});
                });
                var c = 0;
                if (loadArea > 0) {
                    c = _logicService.round(water/loadArea, 2);
                }
                load.composition.push({id: -1, volume: _logicService.round(water, 2), name: "water", consumption: c});
            });

            saveBean.sowingId = $scope.crop.id;

            if (!_.isUndefined(saveBean.sowingId)) {

                var geometryFactory = new jsts.geom.GeometryFactory();
                var startPoint = _mapFieldService.getStartPoint();
                var point = geometryFactory.createPoint(new jsts.geom.Coordinate(startPoint.lat(), startPoint.lng()));

                var geoJSONWriter = new jsts.io.GeoJSONWriter();
                saveBean.startPoint = {point: geoJSONWriter.write(point), angle: _mapFieldService.getStartPointAngle()};
                saveBean.loadsConfig = {tankSize: $scope.spray.tankSize, rate: $scope.spray.rate, method: $scope.spray.method.current.id, fieldSize: $scope.fieldSize, mix: $scope.currentMix.id};
                if ($scope.spray.method.current.id == 0) {
                    saveBean.loadsConfig.insects = _.map(_.filter($scope.insects, function (i) { return i.checked;}), function (i) { return i.id; });
                    saveBean.loadsConfig.danger = _mapFieldService.getCellsDanger();
                }
                if ($scope.spray.method.current.id == 1) {
                    saveBean.loadsConfig.bandwidth = $scope.spray.bandwidth;
                }

                if ($scope.editedSpray) { //update spray
                    saveBean.id = $scope.editedSpray.id;
                    _apiService.updateSpray(_view.getCurrentAccount(), saveBean).then(
                        () => {
                            Notification.success(_i18n.getString("task.notifications.saveAssignment.success"));
                            clearEditSpray();
                            $scope.isLoading = false;
                            _v.change({set: {editSpray: undefined}});
                        },
                        () => {
                            $scope.isLoading = false;
                            Notification.error(_i18n.getString("task.notifications.saveAssignment.error"));
                        }
                    );
                } else {
                    _apiService.saveSpray(_view.getCurrentAccount(), saveBean).then(
                        () => {
                            Notification.success(_i18n.getString("task.notifications.saveAssignment.success"));
                            clearEditSpray();
                            $scope.isLoading = false;
                            _v.change({set: {editSpray: undefined}});
                        },
                        () => {
                            $scope.isLoading = false;
                            Notification.error(_i18n.getString("task.notifications.saveAssignment.error"));
                        }
                    );
                }
            } else {
                $scope.isLoading = false;
                Notification.error(_i18n.getString("task.notifications.saveAssignment.errorNoSowing"));
            }
        };

        $scope.$on('mapFieldService:baseLineCompleted', function(event, data) {
            $scope.fullSprayGrid = data;
        });

        $scope.bandWidthChange = function() {
            _mapFieldService.setBandWidth($scope.spray.bandwidth);
        };

        $scope.selectMix = function(m) {
            $scope.currentMix = m;
        };

        $scope.editMix = function(m) {
            $scope.customMix = m;
        };

        $scope.resetCustomMix = function() {
            $scope.customMix = {
                name: 'Custom mix',
                id: null,
                products: []
            };
        };

        $scope.disableMixRemoving = function() {
            return $scope.customMix.products.length <= 1;
        };

        $scope.gridTypeActive = 0;

        $scope.sprayMethodChange = function() {
            $scope.isLoading = true;
            _mapFieldService.clearSpray();
            sprayDateModel();
            if ($scope.spray.method.current.id == 1) {
                $scope.gridTypeSelect('new', true);
            } else {
                $scope.gridTypeActive = 0;
                //all drawing is inside sprayDateModel() for partial spray
            }
        };


        $scope.gridTypeSelect = function(type, $event) {
            if (_.isUndefined($event)) {
                $scope.isLoading = false;
                return
            }

            $scope.isLoading = true;
            var currentFieldId = (_view.isFieldSelected() || _view.isFarmAndFieldSelected()) ? _view.getCurrentField().id : _view.getPanelField().id;
            _mapFieldService.clearSpray();
            $scope.fullSprayGrid = null;
            $scope.fullSprayGridType = type;
            if (type == "new") {
                $scope.gridTypeActive = 0;
                _mapFieldService.setBandWidth($scope.spray.bandwidth);
                _mapFieldService.startBaselineMode(currentFieldId, $scope.editedSpray);
            }
            if (type == "spray") {
                $scope.gridTypeActive = 1;
                $scope.sprayTrackChange();
            }
            if (type == "cm") {
                $scope.gridTypeActive = 2;
                if ($scope.cropMonitor) {
                    $scope.fullSprayGrid = $scope.cropMonitor.grid;
                    _mapFieldService.drawTaskGrid($scope.cropMonitor.grid);
                    _mapFieldService.drawLoadStart($scope.cropMonitor.grid, $scope.editedSpray);
                }
            }

            $scope.isLoading = false;
        };

        $scope.sprayTrackChange = function() {
            _mapFieldService.clearSpray();
            $scope.fullSprayGrid = null;
            var currentFieldId = (_view.isFieldSelected() || _view.isFarmAndFieldSelected()) ? _view.getCurrentField().id : _view.getPanelField().id;
            if ($scope.input.sprayTrack.id != -1) {
                $scope.isLoading = true;

                _apiService.track(_view.getCurrentAccount(), $scope.input.sprayTrack.id, $scope.input.dividers, $scope.input.selectedTrackOnly ? false : true).then(function (response) {
                    var grid = ($scope.input.selectedTrackOnly ? response.data.grid : response.data.averagedGrid);
                    if (_.isUndefined(grid)) {
                        Notification.error(gettextCatalog.getString("Can't create grid for this track"));
                    } else {
                        $scope.fullSprayGrid = grid;

                        $scope.info = {};
                        $scope.info.rows = grid.ny;
                        $scope.info.cols = grid.nx;
                        $scope.info.width = grid.dx;
                        $scope.info.height = grid.dy;

                        _mapFieldService.drawTaskGrid($scope.fullSprayGrid);
                        _mapFieldService.drawLoadStart($scope.fullSprayGrid, null);
                    }
                    $scope.isLoading = false;
                }, () => {
                    $scope.isLoading = false;
                    Notification.error(_i18n.getString("common.notifications.apiError"));
                });
                $scope.isLoading = false;
            }
        };


        $scope.selectInsectNoteOnSpray = function(insectId) {
            var insect = _.find($scope.insects, function (e) { return e.id == insectId});
            if (insect.selected) {
                if (insect.noteOn) {
                    insect.noteOn = false;
                } else {
                    insect.noteOn = true;
                }
            } else {
                insect.selected = true;
                insect.noteOn = true;
            }

            $scope.drawDangerMap();
        };

        $scope.selectInsectOnSpray = function(insectId) {
            var insect = _.find($scope.insects, function (e) { return e.id == insectId});
            if (insect.selected) {
                insect.selected = false;
                insect.noteOn = false;
            } else {
                insect.selected = true;
            }

            $scope.drawDangerMap();
        };

        $scope.drawDangerMap = function() {
            if ($scope.spray.method.current.id == 0 && $scope.insects) {
                $scope.isLoading = true;
                var insectsIds = [ ];
                var withNotesInsectsIds = [ ];
                _.each($scope.insects, function (e) {
                    if (e.selected) {
                        insectsIds.push(e.id);
                        if (e.task == false) { //pure photonote insect
                            withNotesInsectsIds.push(e.id);
                        } else { //task insect
                            if (e.noteOn) {
                                withNotesInsectsIds.push(e.id);
                            }
                        }
                    }
                });

                $scope.dangerMapNotReady = false;

                if (_.size(insectsIds) > 0) {
                    _apiService.getDangerMap(_view.getCurrentAccount(), $scope.crop, _logicService.javascriptDateToMoment($scope.spray.dateStart.date).format("YYYY-MM-DD"), $scope.editedSpray ? $scope.editedSpray.id : null, insectsIds, withNotesInsectsIds).then(
                        dangerResponse => {
                            $scope.dangerMapNotReady = dangerResponse.status == 202;

                            _mapFieldService.updateDangerCells(dangerResponse.data);
                            _mapFieldService.updateDangerPoints();

                            $scope.isLoading = false;
                        },
                        () => {
                            $scope.isLoading = false;
                            Notification.error(_i18n.getString("common.notifications.apiError"));
                        }
                    );
                } else {
                    _mapFieldService.updateDangerCells([]);
                    _mapFieldService.updateDangerPoints();
                    $scope.isLoading = false;
                }
            }
        };

        $scope.sprayDateChanged = function() {
            $scope.isLoading = true;
            if (!(_.isUndefined($scope.spray.dateStart.date) || _.isNull($scope.spray.dateStart.date))) {
                $scope.spray.dateEnd.date = _logicService.javascriptDateToMoment($scope.spray.dateStart.date).toDate();
            }
            _mapFieldService.clearSpray();
            sprayDateModel();
            if ($scope.spray.method.current.id == 1) {
                $scope.gridTypeSelect('new', true);
            }
        };

        $scope.prevStep = () => {
            if ($scope.step == 2) { //back on step 1
                if ($scope.spray.method.current.id == 1) {
                    if ($scope.fullSprayGridType == "new") {
                        _mapFieldService.enableBaseLine();
                    }
                }
                _mapFieldService.disableCellClick();
            }
            if ($scope.step == 3) { //back on loads step
                _mapFieldService.enableStartMarker();
                _mapFieldService.enableCellClick();
                _mapFieldService.clearLoads();
            }
            --$scope.step;
        };

        $scope.freeSprayChanged = function() {
            buildLoads();
            _mapFieldService.paintLoads($scope.loads, getSprayGrid());
        };

        function getSprayGrid() {
            if ($scope.spray.method.current.id == 0) {
                return $scope.cropMonitor.grid;
            } else {
                return $scope.fullSprayGrid;
            }
        }

        function buildLoads() {
            _mapFieldService.clearLoads();
            var grid = getSprayGrid();
            var cellsInTank = Math.floor(($scope.spray.tankSize / $scope.spray.rate) / (grid.dx*grid.dy * 0.0001));
            $scope.loads = [];
            var currentLoad = {"cells": _mapFieldService.startLoads(grid), "id": 1};
            currentLoad.color = colors[(currentLoad.id-1) % colors.length];
            var cellsToAdd;
            do {
                cellsToAdd = _mapFieldService.nextCells(grid);
                if (_.size(cellsToAdd) > 0) {
                    if (_.size(currentLoad.cells) + _.size(cellsToAdd) <= cellsInTank) {
                        currentLoad.cells = currentLoad.cells.concat(cellsToAdd);
                    } else {
                        $scope.loads.push(_.clone(currentLoad));  //full load
                        currentLoad = {"cells": cellsToAdd, "id": currentLoad.id + 1};
                        currentLoad.color = colors[(currentLoad.id-1) % colors.length];
                    }
                } else {
                    if (_.size(currentLoad.cells) > 0) {
                        $scope.loads.push(_.clone(currentLoad));  //last load
                    }
                }
            } while (_.size(cellsToAdd) > 0);

            if ($scope.spray.freeSpray) { //merge all load in one
                if (_.size($scope.loads) > 0) {
                    var mergedCells = [];
                    _.each($scope.loads, function (l) {
                        mergedCells = mergedCells.concat(l.cells);
                    });
                    $scope.loads = [{"id": 1, "cells": mergedCells, "color": colors[0]}];
                }
            }
        }

        $scope.nextStep = function(form) {
            var currentFieldId = (_view.isFieldSelected() || _view.isFarmAndFieldSelected()) ? _view.getCurrentField().id : _view.getPanelField().id;

            if ($scope.step == 1) {
                // Check if form is valid
                if (!form.$valid) {
                    // Validate all fields
                    _.forEach(form, value => {
                        if (typeof value === 'object' && value.hasOwnProperty('$modelValue')) {
                            value.$setDirty();
                        }
                    });

                    return;
                }
                //select cells
                if ($scope.spray.method.current.id == 0) {
                    _mapFieldService.selectBadCells();
                    _mapFieldService.enableCellClick();
                } else {
                    _mapFieldService.selectCellsWithAreaGreaterThan(0.1, currentFieldId);
                    _mapFieldService.disableBaseLine();
                    _mapFieldService.enableCellClick();
                }
            }

            if ($scope.step == 2) {
                var grid = getSprayGrid();
                _mapFieldService.fixStartMarker();
                _mapFieldService.disableCellClick();
                var cellsInTank = Math.floor(($scope.spray.tankSize / $scope.spray.rate) / (grid.dx*grid.dy * 0.0001));
                if (cellsInTank < grid.nx*2) {
                    Notification.error(gettextCatalog.getString('Tank too small to cover two field rows. Loads can not be designed'));
                } else {
                    $scope.isLoading = true;
                    $scope.loads = [];
                    $scope.mixes = [];

                    buildLoads();
                    _mapFieldService.paintLoads($scope.loads, grid);

                    _apiService.chemicals(_view.getCurrentAccount()).then(
                        response => {
                            $scope.products = [];

                            response.data.forEach(chemical => {
                                $scope.products.push({
                                    id: chemical.id,
                                    name:  chemical.brand + " (" + _i18n.getStringFromBundleEn(chemical.ingredient) + ")",
                                    image: "/img/chemical.png"
                                });
                            });

                            _apiService.mixes(_view.getCurrentAccount()).then(
                                response => {

                                    response.data.forEach(mix => {
                                        mix.entries.forEach(entry => {
                                            entry.name = entry.chemical.ingredient + " (" + entry.chemical.brand + ")";
                                            entry.id = entry.chemical.id;
                                        });

                                        $scope.mixes.push({
                                            id: mix.id,
                                            name: mix.name,
                                            products: mix.entries
                                        });
                                    });

                                    if (!$scope.currentMix && $scope.editedSpray) { //init mix from edited spray
                                        $scope.currentMix = _.find($scope.mixes, function(m) {return m.id == $scope.editedSpray.loadsConfig.mix});
                                    }
                                },
                                () => {
                                    Notification.error(_i18n.getString("common.notifications.apiError"));
                                }
                            );
                            $scope.isLoading = false;
                        },
                        () => {
                            $scope.isLoading = false;
                            Notification.error(_i18n.getString("common.notifications.apiError"));
                        }
                    );
                }
            }

            ++$scope.step;
        };


        function clearViewSprayWhenTasksWereSwitched(tasks) {
            if (tasks.prev !== tasks.current) {
                clearViewSpray();
            }

            initViewSpray();
        }

        function initViewSpray() {
            if (_view.is.viewSpray()) {
                $scope.isLoading = true;

                document.querySelector('body').onkeydown = function (e) {
                    if (e.keyCode == 27) {
                        document.querySelector('body').onkeydown = null;
                        $rootScope.$safeApply(function() {
                            $scope.closeViewSpray();
                        });
                    }
                };

                _apiService.getSpray(_view.getCurrentAccount(), _view.getViewSpray()).then(
                    sprayResponse => {
                        $scope.viewSpray = sprayResponse.data;

                        $scope.viewSpray.from = moment.utc($scope.viewSpray.datePublished);
                        $scope.viewSpray.to = moment.utc($scope.viewSpray.dateDue);

                        $scope.viewSpray.dateStart = _formatService.formatDateAndTime($scope.viewSpray.from);
                        $scope.viewSpray.dateEnd = _formatService.formatDateAndTime($scope.viewSpray.to);

                        var fieldId = (_view.isFieldSelected() || _view.isFarmAndFieldSelected()) ? _view.getCurrentField().id : _view.getPanelField().id;
                        if (_view.is.map()) {
                            _mapFieldService.hideAllFieldsExceptOne(fieldId);
                            _mapFieldService.panToFields([{id: fieldId}]);
                            _mapFieldService.drawTaskGrid($scope.viewSpray.grid);
                            _mapFieldService.drawLoadStart($scope.viewSpray.grid, $scope.viewSpray);
                            _mapFieldService.fixStartMarker();
                            _mapFieldService.startLoads($scope.viewSpray.grid);
                            _mapFieldService.paintLoads($scope.viewSpray.loads, $scope.viewSpray.grid);

                            $scope.viewSpray.totalComposition = [];

                            for (var i = 0; i < $scope.viewSpray.loads.length; i++) {
                                var load = $scope.viewSpray.loads[i];
                                if (i == 0) {
                                    load.composition.forEach(product => {
                                        $scope.viewSpray.totalComposition.push({
                                            id: product.id,
                                            name: product.name,
                                            volume: product.volume,
                                            consumption: product.consumption
                                        });
                                    });
                                } else {
                                    load.composition.forEach(product => {
                                        _.find($scope.viewSpray.totalComposition, function (e) {
                                            return e.id == product.id;
                                        }).volume += product.volume;
                                    });
                                }
                            }

                            _.each($scope.viewSpray.totalComposition, function(x) {
                                x.volume = _logicService.round(x.volume, 2);
                            });
                        }

                        if ($scope.viewSpray.type == "PARTIAL") {
                            _apiService.fieldCrops(_view.getCurrentAccount(), fieldId).then(function (d) {
                                if (_.size(d.data) > 0) {
                                    $scope.crops = d.data;
                                    $scope.crop = _.find($scope.crops, function (c) {
                                        return c.id === $scope.viewSpray.sowing.id;
                                    });

                                    var cropMonitor = findCropMonitor($scope.viewSpray.from, $scope.crop);

                                    if (cropMonitor) {
                                        _apiService.getTaskDetails(_view.getCurrentAccount(), cropMonitor.id).then(
                                            response => {
                                                $scope.cropMonitor = response.data;

                                                _apiService.getInsects(_view.getCurrentAccount()).then(
                                                    insectsResponse => {

                                                        if (_view.is.map()) {
                                                            _mapFieldService.drawTaskResults($scope.cropMonitor.points);
                                                            _mapFieldService.updateDangerCellsFromSnapshot($scope.viewSpray.loadsConfig.danger);
                                                            _mapFieldService.updateDangerPoints();

                                                            $scope.viewSpray.insectsText = "";
                                                            insectsResponse.data.forEach(insect => {
                                                                if ($scope.viewSpray.loadsConfig.insects.includes(insect.id)) {
                                                                    if ($scope.viewSpray.insectsText) {
                                                                        $scope.viewSpray.insectsText = $scope.viewSpray.insectsText + ", ";
                                                                    }
                                                                    $scope.viewSpray.insectsText = $scope.viewSpray.insectsText + _i18n.getStringFromBundleLat(insect.name);
                                                                }
                                                            });

                                                            $scope.isLoading = false;
                                                        } else if (_view.is.weather()) { //weather

                                                            $scope.viewSpray.insects = [];
                                                            insectsResponse.data.forEach(insect => {
                                                                if ($scope.viewSpray.loadsConfig.insects.includes(insect.id)) {
                                                                    $scope.viewSpray.insects.push({
                                                                        id: insect.id,
                                                                        formName: _i18n.getStringFromBundleLat(insect.name),
                                                                        maxThreshold: insect.maxThreshold
                                                                    });
                                                                }
                                                            });
                                                            $scope.viewSpray.insect = null;
                                                            $scope.isLoading = false;
                                                        }
                                                    },
                                                    () => {
                                                        $scope.isLoading = false;
                                                        Notification.error(_i18n.getString("common.notifications.apiError"));
                                                    }
                                                );
                                            },
                                            () => {
                                                $scope.isLoading = false;
                                                Notification.error(_i18n.getString("common.notifications.apiError"));
                                            }
                                        );
                                    } else {
                                        $scope.isLoading = false;
                                        Notification.error(gettextCatalog.getString('Previous crop monitor with status done not found for this date'));
                                        $scope.close();
                                    }
                                }
                            }, function () {
                                $scope.isLoading = false;
                                Notification.error(_i18n.getString("common.notifications.apiError"));
                            });
                        } else {
                            $scope.isLoading = false;
                        }
                    }, function () {
                        $scope.isLoading = false;
                        Notification.error(_i18n.getString("common.notifications.apiError"));
                    }
                );
            } else {
                clearViewSpray();
            }
        }

        $scope.scaleY = GRAPH_X1;

        $scope.selectInsect = (insectId) => {

            if ($scope.viewSpray.insect && $scope.viewSpray.insect.id == insectId) {
                $scope.viewSpray.insect = null;
            } else {
                $scope.viewSpray.insect = _.find($scope.viewSpray.insects, function (i) {
                    return i.id == insectId;
                });
            }

            _chartService.clearSprayGraph();
            if (_.isNull($scope.viewSpray.insect) || _.isUndefined($scope.viewSpray.insect)) {
                return;
            }

            $scope.isLoading = true;

            _apiService.thresholdUnit(_view.getCurrentAccount(), $scope.cropMonitor.id, $scope.viewSpray.insect.id).then(
                unitResponse => {
                    $scope.thresholdUnit = unitResponse.data.thresholdUnit;

            _apiService.sowingThresholds(_view.getCurrentAccount(), $scope.cropMonitor.sowing.id, $scope.viewSpray.insect.id).then(
                thresholdsResponse => {
                    $scope.thresholds = thresholdsResponse.data;

                    _apiService.sowingInsects(_view.getCurrentAccount(), $scope.cropMonitor.sowing.id, $scope.viewSpray.insect.id, false).then(
                        sowingInsectsResponse => {
                            $scope.insectsGraphDataNotReady = sowingInsectsResponse.status == 202;
                            $scope.insectsGraphData = sowingInsectsResponse.data;

                            _chartService.buildSprayGraphModel($scope.scaleY, $scope.thresholds, $scope.thresholdUnit, $scope.insectsGraphData, $scope.viewSpray.insect);

                            $scope.zoomIn = function() {
                                $scope.scaleY *= GRAPH_HALF;
                                _chartService.buildSprayGraphModel($scope.scaleY, $scope.thresholds, $scope.thresholdUnit, $scope.insectsGraphData, $scope.viewSpray.insect);
                            };

                            $scope.zoomOut = function() {
                                $scope.scaleY *= GRAPH_X2;
                                _chartService.buildSprayGraphModel($scope.scaleY, $scope.thresholds, $scope.thresholdUnit, $scope.insectsGraphData, $scope.viewSpray.insect);
                            };

                            $scope.isLoading = false;
                        },
                        () => {
                            $scope.isLoading = false;
                            Notification.error(_i18n.getString("common.notifications.apiError"));
                        }
                    );
                },
                () => {
                    $scope.isLoading = false;
                    Notification.error(_i18n.getString("common.notifications.apiError"));
                }
            );

            }, () => { //_apiService.thresholdUnit
                $scope.isLoading = false;
                Notification.error(_i18n.getString("common.notifications.apiError"));
            });
        };

        $scope.$on("_calendar:width-changed", function() {
            if (_view.is.viewSpray() && _view.is.weather() && $scope.viewSpray && $scope.viewSpray.insect && $scope.thresholds && $scope.insectsGraphData) {
                _chartService.buildSprayGraphModel($scope.scaleY, $scope.thresholds, $scope.thresholdUnit, $scope.insectsGraphData, $scope.viewSpray.insect);
            }
        });

        $scope.$on("_calendar:changed", function() {
            if (_view.is.viewSpray() && _view.is.weather() && $scope.viewSpray && $scope.viewSpray.insect && $scope.thresholds && $scope.insectsGraphData) {
                _chartService.buildSprayGraphModel($scope.scaleY, $scope.thresholds, $scope.thresholdUnit, $scope.insectsGraphData, $scope.viewSpray.insect);
            }
        });

        function clearEditSpray() {
            $scope.spray = null;
            $scope.editedSpray = null;
            $scope.cropMonitor = null;
            _mapFieldService.clearSpray();
            _mapAddTaskService.switchMode();
            _mapFieldService.showAllFields();
        }

        function clearViewSpray() {
            $scope.viewSpray = null;
            $scope.cropMonitor = null;
            $scope.scaleY = GRAPH_X1;
            _mapFieldService.clearSpray();
            _mapFieldService.showAllFields();
            _chartService.clearSprayGraph();
        }

        $scope.closeViewSpray = function() {
            clearViewSpray();
            _v.change({set: {viewSpray: undefined}});
        };

        $scope.closeEditSpray = function() {
            clearEditSpray();
            _v.change({set: {editSpray: undefined}});
        };

        $scope.editSpray = function() {
            clearViewSpray();
            if (_view.is.map()) {
                _v.change({set: {viewSpray: undefined, editSpray: _view.getViewSpray()}});
            } else {
                _v.change({set: {viewSpray: undefined, editSpray: _view.getViewSpray(), view: 'm'}});
            }
        };

        function findCropMonitor(from, crop) {
            var result = null;

            if (crop) {
                var previousDoneCM = null;
                crop.tasks.forEach(task => {
                    if (task.type == "cropmonitor" && (task.status == "DONE" || task.status == "ABANDONED")) {
                        if (moment.utc(task.from).isBefore(moment.utc(from))) {
                            if (previousDoneCM != null) {
                                if (moment.utc(previousDoneCM.from).isBefore(moment.utc(task.from))) {
                                    previousDoneCM = task;
                                }
                            } else {
                                previousDoneCM = task;
                            }
                        }
                    }
                });

                if (previousDoneCM != null) {
                    //previousDoneCM holds previous DONE crop monitor here
                    result = previousDoneCM;
                    crop.tasks.forEach(task => {
                        if (task.type == "cropmonitor" && task.status != "DONE" && task.status != "ABANDONED") {
                            if (moment.utc(task.from).isBetween(moment.utc(previousDoneCM.from), moment.utc(from), null, "[]")) {
                                result = null;
                            }
                        }
                    });
                }
            }
            return result;
        }

        $scope.deleteSpray = function() {

            $scope.isLoading = true;

            var $uibModalInstance = $uibModal.open({
                openedClass: 'modal-open modal-deleteSpray',
                templateUrl: 'modal-deleteSpray',
                controllerAs: 'vm',
                controller: function($uibModalInstance) {

                    var vm = this;

                    // Cancel
                    vm.cancel = function() {
                        $uibModalInstance.close('cancel');
                    };

                    // Delete
                    vm.delete = function() {
                        $uibModalInstance.close('confirm');
                    };
                }
            });

            $uibModalInstance.result.then(function (msg) {
                if (msg == 'confirm') {
                    _apiService.deleteSpray(_view.getCurrentAccount(), $scope.viewSpray.id).then(success => {

                        _flashService.put('successMessage', gettextCatalog.getString('Task was deleted'));
                        window.location.href = _v.getUrl({set: {viewSpray: undefined}});
                        $scope.isLoading = false;
                    }, error => {
                        $scope.isLoading = false;
                        Notification.error(gettextCatalog.getString('Task was not deleted'));
                    });
                } else {
                    $scope.isLoading = false;
                }
            });

            $uibModalInstance.closed.then(function (msg) {
                $scope.isLoading = false;
            });

        };

    }
);